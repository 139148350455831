import React from 'react';
import Seo from '../../components/Seo';
import Section from '../../components/Section';
import Footer from '../../components/Footer';
import { Box, Grid, makeStyles } from '@material-ui/core';
import '../../fonts/fonts.css';
import Typography from '../../components/Typography';

import logo from '../../images/logo_navy.svg';
import phonecircle from '../../images/phonecircle.svg';
import appstore from '../../images/apple-appstore-black.svg';
import playstore from '../../images/google-play-black.svg';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    backgroundColor: '#FFFBFA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer:{
    width: '206px',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '32px',
      paddingBottom: '32px',
    },
  },
  webLink: {
    '&:link': {color: 'rgb(4, 0, 104)',},
    '&:visted': {color: 'rgb(4, 0, 104)',}
  }
}));

const LinkFitness = () => {
  const classes = useStyles();

    return(
    <>
      <Seo
        title={ 'Download Innowell' }
      />
      <Box sx={{
        fontFamily: 'PangramSansMed, PangramSansRounded',
        backgroundColor: '#FFFFFF',
        color: '#040068',
      }}>
        <Grid container className={classes.headerContainer}>
          <Grid item>
            <Box className={classes.logoContainer}>
              <a href='/'>
                <img src={logo} alt='innowell logo' objectFit='fill' />
              </a>
            </Box>
          </Grid>
        </Grid>
        <Section theme='secondary'>
          <Grid 
            container
            spacing={2}
            columns={{xs:2, sm:4, md:12 }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <img src={phonecircle} alt='Innowell mobile app' width='95%'/>
            </Grid>
            <Grid item xs={12}>
              <Typography type='heading' preset={2} align='center'>Take Your Mental Wellness Companion With You In Your Pocket</Typography>
            </Grid>
            <Grid xs={12}>
            <Typography type='paragraph' preset={2} align='center'>The team here at Innowell has worked hard to make your mental health companion available for you whenever and wherever you need, and we hope this makes unlocking better mental wellness just a little easier.</Typography>
            <Typography type='paragraph' preset={2} align='center'>The Innowell app can support you today with the tools and resources to best practice self-care for your mental health, and in the near future it will enable you to work with your clinician to manage your mental health care plan.</Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={4}>
              <Grid item>
                  <a href='https://apps.apple.com/us/app/innowell/id1658655122'>
                    <img src={appstore} alt='Apple AppStore Badge' style={{maxWidth:'200px'}} />
                  </a>
              </Grid>
              <Grid item>
                  <a href='https://play.google.com/store/apps/details?id=org.innowell.thrivemobile'>
                    <img src={playstore} alt='Google Play Badge' style={{maxWidth:'200px'}} />
                  </a>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{pt: {xs:'16px', sm:'32px'}, pb: '0px'}}>
                <a className={classes.webLink} href='https://individual.innowell.org'>
                  <Typography type='paragraph' preset={2} align='center'>Or you can use the web version here</Typography>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* Footer */}
        <Section theme='primary' variant='narrow'>
          <Footer />
        </Section>
      </Box>
    </>
    );
};

export default LinkFitness;